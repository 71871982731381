import {
  Heading,
  Image,
  LinkWithButtonStyles,
} from '@frontendmentorio/design-system-v2';

export default function Hiring() {
  return (
    <div className="[background-image:theme(backgroundImage.bgPatternDesktop1)] [background-position:bottom_-12rem_left_50vw] bg-no-repeat bg-primaryBlue px-5 relative desktop:[background-position:bottom_0_left_60vw]">
      <div className="container-xl flex flex-col gap-6 items-center desktop:flex-row desktop:items-end">
        <div className="flex flex-col gap-6 items-center max-w-prose pt-8 text-center text-neutralWhite desktop:items-start desktop:py-9 desktop:text-start">
          <Heading level="3" tagName="h2">
            Are you looking to hire talented, passionate developers?
          </Heading>

          <p className="text-lg">
            Our Hiring Platform helps you connect with our diverse community of
            talented early-career developers. Stop wading through hundreds of
            resumes and get chatting to skilled developers in minutes.
          </p>

          <LinkWithButtonStyles
            className="text-primaryBlue"
            href={process.env.HIRING_PLATFORM_URL}
            level="tertiary"
          >
            Start hiring developers
          </LinkWithButtonStyles>
        </div>

        <Image
          alt=""
          className="max-w-[36rem] w-full"
          height={732}
          quality={100}
          src="/static/images/home-hiring.webp"
          width={1014}
        />
      </div>
    </div>
  );
}
