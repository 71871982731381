import {
  Heading,
  Icon,
  LinkWithButtonStyles,
} from '@frontendmentorio/design-system-v2';

export default function Pro() {
  return (
    <div className="px-5 py-9">
      <div className="container-xl flex flex-col items-center gap-8">
        <div className="flex flex-col gap-5 items-center max-w-prose text-center">
          <Heading className="leading-snug" level="3" tagName="h2">
            Take your skills to the next level with our{' '}
            <span className="bg-primaryBlue font-bold rounded-lg text-neutralWhite uppercase px-2">
              Pro
            </span>{' '}
            subscription
          </Heading>

          <p className="text-lg text-neutralDarkGrey">
            Step into the shoes of a professional developer and build projects
            in the most realistic way possible. Our Pro subscription will help
            you prepare for life as a professional developer.
          </p>
        </div>

        <div className="flex flex-col gap-5 max-w-prose text-center tablet:flex-row tablet:max-w-none">
          <div className="bg-neutralWhite flex flex-col gap-5 items-center rounded-xl px-5 py-7">
            <Icon
              className="text-primaryBlue text-[3.75rem]"
              name="figma"
              style="brands"
            />

            <Heading level="4" tagName="h3">
              Access each challenge’s design file
            </Heading>

            <p className="text-lg text-neutralDarkGrey">
              Professional teams use tools like Figma. By using these tools when
              building projects, you’ll save time, create more accurate
              solutions, and gain hands-on experience working like a pro.
            </p>
          </div>

          <div className="bg-neutralWhite flex flex-col gap-5 items-center rounded-xl px-5 py-7">
            <Icon
              className="text-primaryBlue text-[3.75rem]"
              name="star-shooting"
              style="light"
            />

            <Heading level="4" tagName="h3">
              Tackle our premium challenges
            </Heading>

            <p className="text-lg text-neutralDarkGrey">
              Our premium challenges are the most realistic, comprehensive
              practice projects you’ll find. They also make incredible portfolio
              pieces to help you “wow!” recruiters and get hired.
            </p>
          </div>
        </div>

        <LinkWithButtonStyles href="/pro">Learn about Pro</LinkWithButtonStyles>
      </div>
    </div>
  );
}
