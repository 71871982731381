import { selectCount } from 'features/learners/slice';
import { useSelector } from 'react-redux';

import { PageIntro } from '@frontendmentorio/design-system-v2';

import GitHubAuthButton from 'features/shared/GitHubAuthButton';

export default function CTA() {
  const userCount = useSelector(selectCount);

  return (
    <PageIntro
      className="flex flex-col gap-6 items-center"
      heading={`Join ${userCount} people building portfolio-worthy projects`}
      headingLevel="2"
    >
      <p className="max-w-prose text-neutralDarkGrey">
        Our highly supportive, positive community is here to help you improve
        your skills. We all try to help each other out wherever possible. We’d
        love to welcome you to our community!
      </p>

      <GitHubAuthButton level="secondary" />
    </PageIntro>
  );
}
