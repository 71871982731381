import { useState } from 'react';

import { Heading } from '@frontendmentorio/design-system-v2';

import Images from './Images';
import Step from './Step';

export default function HowItWorks() {
  const [activeStep, setActiveStep] = useState(1);

  return (
    <div className="px-5">
      <div className="bg-neutralLightestGrey overflow-hidden rounded-xl">
        <div className="container-sm flex flex-col px-5 py-9 desktop:container-xl desktop:flex-row-reverse desktop:gap-5">
          <Images activeStep={activeStep} />

          <div className="flex flex-col gap-6 items-center desktop:items-start desktop:w-1/2">
            <Heading level="3" tagName="h2">
              How it works
            </Heading>

            <div className="flex flex-col gap-4">
              <Step
                step={1}
                heading="Choose your challenge"
                isActive={activeStep === 1}
                onClick={() => setActiveStep(1)}
              >
                Browse our collection of professionally designed projects. Pick
                one that suits the level you’re currently at.
              </Step>

              <Step
                step={2}
                heading="Code the design"
                isActive={activeStep === 2}
                onClick={() => setActiveStep(2)}
              >
                Each project comes with all files included. This means you can
                focus on coding the project using the design as a reference.
              </Step>

              <Step
                step={3}
                heading="Submit your solution"
                isActive={activeStep === 3}
                onClick={() => setActiveStep(3)}
              >
                Get feedback from the community about your code and see how
                close you got to the design.
              </Step>

              <Step
                step={4}
                heading="Give others feedback"
                isActive={activeStep === 4}
                onClick={() => setActiveStep(4)}
              >
                Reviewing other people’s code is a vital part of being a
                developer. Practice giving code reviews and help others improve.
              </Step>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
