import FAQs from 'features/shared/FAQs';

import { Heading } from '@frontendmentorio/design-system-v2';

export default function HomeFAQs() {
  return (
    <div className="px-5 py-9">
      <div className="container-sm flex flex-col gap-7 items-center">
        <Heading level="3" tagName="h2">
          FAQs
        </Heading>

        <FAQs>
          <FAQs.FAQ question="Is it free?" />
          <FAQs.FAQ question="Can I use these projects in my portfolio?" />
          <FAQs.FAQ question="Can I take the challenges as a beginner?" />
          <FAQs.FAQ question="How do I start?" />
          <FAQs.FAQ question="How can I get help if I'm stuck on a challenge?" />
          <FAQs.FAQ question="Can I use libraries/frameworks on these projects?" />
          <FAQs.FAQ question="Am I guaranteed to get hired through the Hiring Platform?" />
        </FAQs>
      </div>
    </div>
  );
}
