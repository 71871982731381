import { selectCount } from 'features/learners/slice';
import { useSelector } from 'react-redux';

import { Heading, Image, Jumbotron } from '@frontendmentorio/design-system-v2';

import GitHubAuthButton from 'features/shared/GitHubAuthButton';

export default function Hero() {
  const userCount = useSelector(selectCount);

  return (
    <Jumbotron
      className="overflow-hidden px-5 p-6 tablet:py-8"
      tagName="section"
    >
      <div className="container-xl flex flex-col gap-6 tablet:flex-row tablet:gap-2 tablet:pt-2">
        <div className="flex flex-col gap-5 items-start justify-center tablet:max-w-[38.5rem] tablet:min-w-[30rem] tablet:w-[65%]">
          <Heading className="text-primaryBlue" level="1" tagName="h2">
            Improve your coding skills by building realistic projects
          </Heading>

          <p className="text-neutralDarkGrey text-lg">
            Our professionally designed challenges help you gain hands-on
            experience writing HTML, CSS, and JavaScript. We create the designs
            so you can focus on the code and see your skills skyrocket!
          </p>

          <GitHubAuthButton level="secondary" />

          <div className="bg-neutralWhite flex flex-col gap-4 items-center max-w-prose p-5 rounded-xl text-center mobile:flex-row mobile:text-start">
            <Image
              alt=""
              className="w-[12rem]"
              height={142}
              priority
              quality={100}
              src="/static/images/home-community-avatars.webp"
              width={368}
            />

            <p>
              Join{' '}
              <span className="font-bold text-primaryBlue">{userCount}</span>{' '}
              developers building projects, reviewing code, and helping each
              other improve.
            </p>
          </div>
        </div>

        <div className="tablet:h-[34rem]">
          <Image
            alt=""
            className="tablet:absolute tablet:w-[48rem]"
            height={1218}
            priority
            src="/static/images/home-hero.webp"
            width={1590}
          />
        </div>
      </div>
    </Jumbotron>
  );
}
