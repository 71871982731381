import { Heading } from '@frontendmentorio/design-system-v2';

import Card from './Card';
import Carousel from './Carousel';

export default function Community() {
  return (
    <div className="px-5">
      <div className="bg-neutralLightestGrey rounded-xl px-5 py-9">
        <div className="container-xl gap-7 grid justify-items-center mobile:justify-items-start tablet:grid-cols-[1fr_min-content] tablet:grid-rows-[min-content_1fr]">
          <Heading
            className="text-center mobile:text-start"
            level="3"
            tagName="h2"
          >
            A little ❤️ from our community
          </Heading>

          <Carousel>
            <Card
              avatar="/static/images/home-avatar-francesca.webp"
              id="carousel-card-1"
              name="Francesca"
              username="frrann"
            >
              This platform provides everything developers need to improve their
              skills. The community is great, and the challenges keep me
              motivated, instill accountability through regular submissions, and
              allow me to build a professional portfolio by showcasing diverse
              projects.
            </Card>

            <Card
              avatar="/static/images/home-avatar-alfie.webp"
              id="carousel-card-2"
              name="Alfie"
              username="alfiemitchell123"
            >
              Frontend Mentor eliminated the hurdle of sourcing designs, letting
              me focus on development. Their professional projects, especially
              in the Pro subscription, challenge me to create complex,
              multi-page websites. With a supportive community and feedback from
              advanced programmers, my skills have been elevated to new heights.
            </Card>

            <Card
              avatar="/static/images/home-avatar-hikmah.webp"
              id="carousel-card-3"
              name="Hikmah"
              username="Hikmahx"
            >
              Frontend Mentor transformed me from a newbie to a professional
              developer, enabling me to create flawless, responsive, accessible
              websites. It’s a superb platform for feedback with a supportive
              community backing your growth. Through it, I evolved from a
              frontend to a full-stack developer.
            </Card>

            <Card
              avatar="/static/images/home-avatar-nuel.webp"
              id="carousel-card-4"
              name="Nuel"
              username="ijklmopffs"
            >
              Frontend Mentor has helped tremendously. Working on the newbie
              challenges really helped me get better at building layouts. Also,
              it increased my JavaScript skills. Working with APIs, DOM
              manipulation, and so on greatly increased my knowledge. Would
              totally recommend it.
            </Card>

            <Card
              avatar="/static/images/home-avatar-michelle.webp"
              id="carousel-card-5"
              name="Michelle"
              username="maberizk"
            >
              After completing a software bootcamp, Frontend Mentor let me build
              real-world projects, strengthening my developer skills. I wouldn’t
              be where I am today without it.
            </Card>

            <Card
              avatar="/static/images/home-avatar-arturo.webp"
              id="carousel-card-6"
              name="Arturo"
              username="artimys"
            >
              As a backend developer, I sought to improve my frontend skills.
              Frontend Mentor provided beautifully designed challenges, allowing
              me to progress from simple components to advanced responsive web
              pages. Enough can’t be said about the community members. Their
              feedback has been invaluable in my journey to becoming a better
              developer.
            </Card>

            <Card
              avatar="/static/images/home-avatar-shashikant.webp"
              id="carousel-card-7"
              name="Shashikant"
              username="shashikantdev3"
            >
              Frontend Mentor offers invaluable real-world web development
              challenges, sharpening my HTML, CSS, and JavaScript skills. The
              structured feedback, solutions, and vibrant community have
              significantly impacted my learning and growth. It’s both a
              skill-building platform and a community hub for web developers.
            </Card>

            <Card
              avatar="/static/images/home-avatar-marit.webp"
              id="carousel-card-8"
              name="Marit"
              username="Maritxx"
            >
              I began my frontend journey with Frontend Mentor’s newbie
              challenges and progressed to more complex projects. Some of the
              complex ones are now even in my portfolio! The supportive
              community and the dedication of the Frontend Mentor team are
              unmatched. I highly recommend it for newcomers and those keen on
              improving their skills.
            </Card>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
