import { tv } from 'tailwind-variants';

import { Image } from '@frontendmentorio/design-system-v2';

const imageStyles = tv({
  base: [
    'absolute',
    'duration-250',
    'left-0',
    'max-w-none',
    'opacity-0',
    'bottom-0',
    'transition-opacity',
    'w-full',
    'desktop:h-[42rem]',
    'desktop:top-[-2rem]',
    'desktop:w-auto',
  ],
  variants: {
    isActive: {
      true: ['opacity-100'],
    },
  },
});

export default function Images({ activeStep }) {
  return (
    <div className="h-[40vw] max-h-[24rem] relative desktop:h-full desktop:w-1/2">
      <Image
        alt=""
        className={imageStyles({ isActive: activeStep === 1 })}
        height={1049}
        quality={100}
        src="/static/images/home-how-it-works-1.webp"
        width={1200}
      />

      <Image
        alt=""
        className={imageStyles({ isActive: activeStep === 2 })}
        height={1049}
        quality={100}
        src="/static/images/home-how-it-works-2.webp"
        width={1200}
      />

      <Image
        alt=""
        className={imageStyles({ isActive: activeStep === 3 })}
        height={1049}
        quality={100}
        src="/static/images/home-how-it-works-3.webp"
        width={1200}
      />

      <Image
        alt=""
        className={imageStyles({ isActive: activeStep === 4 })}
        height={1049}
        quality={100}
        src="/static/images/home-how-it-works-4.webp"
        width={1200}
      />
    </div>
  );
}
