import NextLink from 'next/link';

import { Image, OrderedFeature } from '@frontendmentorio/design-system-v2';

export default function Benefits() {
  return (
    <div className="pb-9 pt-6 px-5">
      <div className="container-xl flex flex-col gap-10 tablet:gap-8">
        <h2 className="sr-only">Why Frontend Mentor?</h2>

        <OrderedFeature
          emoji="😈"
          heading="Escape tutorial hell"
          image={
            <Image
              alt=""
              height={870}
              src="/static/images/home-benefit-1.webp"
              width={945}
            />
          }
          number="1"
        >
          Add projects to your learning journey and put your knowledge to the
          test. The real learning happens when you start solving real-world
          problems yourself.
        </OrderedFeature>

        <OrderedFeature
          emoji="🤩"
          heading="Build portfolio-worthy projects"
          image={
            <Image
              alt=""
              height={1137}
              src="/static/images/home-benefit-2.webp"
              width={1070}
            />
          }
          number="2"
        >
          Design is hard. We take care of the project ideas and design so you
          can focus on the coding. You’ll end up with an incredible portfolio of
          stunning projects!
        </OrderedFeature>

        <OrderedFeature
          emoji="🚀"
          heading="Banish impostor syndrome"
          image={
            <Image
              alt=""
              height={1022}
              src="/static/images/home-benefit-3.webp"
              width={950}
            />
          }
          number="3"
        >
          We’ve all felt out of our depth before. Getting hands-on experience is
          an incredible way to build confidence, refine your workflow, and
          supercharge your learning.
        </OrderedFeature>

        <OrderedFeature
          emoji="🛠️"
          heading="Practice new tools"
          image={
            <Image
              alt=""
              height={1136}
              src="/static/images/home-benefit-4.webp"
              width={1120}
            />
          }
          number="4"
        >
          The front-end landscape changes constantly. Our design-led challenges
          let you pick your tools to help you adapt, experiment, and keep up
          with the latest trends.
        </OrderedFeature>

        <OrderedFeature
          emoji="🤝"
          heading="Create job opportunities"
          image={
            <Image
              alt=""
              height={968}
              src="/static/images/home-benefit-5.webp"
              width={1120}
            />
          }
          number="5"
        >
          We have a{' '}
          <NextLink
            className="underline hover:no-underline"
            href={process.env.HIRING_PLATFORM_URL}
          >
            Hiring Platform
          </NextLink>{' '}
          that runs alongside this platform. Just by building projects and
          engaging with the community, you could open up job opportunities!
        </OrderedFeature>
      </div>
    </div>
  );
}
