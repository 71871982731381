import { tv } from 'tailwind-variants';

import { Bubble } from '@frontendmentorio/design-system-v2';

const styles = tv({
  slots: {
    wrapper: [
      'bg-neutralWhite',
      'border',
      'border-neutralLightGrey',
      'rounded-xl',
    ],
    bubble: [
      'bg-primaryCyan',
      'font-secondary',
      'font-bold',
      'italic',
      'text-neutralWhite',
    ],
    heading: ['font-secondary', 'font-semibold', 'text-lg'],
  },
  variants: {
    isActive: {
      true: {
        wrapper: ['bg-primaryBlue', 'border-primaryBlue'],
        bubble: ['bg-neutralWhite', 'text-primaryBlue'],
        heading: ['text-neutralWhite'],
      },
    },
  },
});

export default function Step({ children, heading, isActive, onClick, step }) {
  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };

  const { wrapper, bubble, heading: headingStyles } = styles({ isActive });

  return (
    <details className={wrapper()} onClick={handleClick} open={isActive}>
      <summary className="cursor-pointer flex gap-4 items-center list-none px-5 py-4 [&::-webkit-details-marker]:hidden">
        <Bubble className={bubble()} size="sm">
          {step}
        </Bubble>

        <span className={headingStyles()}>{heading}</span>
      </summary>

      <p className="p-5 pt-0 text-neutralLightGrey">{children}</p>
    </details>
  );
}
