import { Testimonials } from '@frontendmentorio/design-system-v2';

export default function HomeTestimonials() {
  return (
    <div className="px-5">
      <Testimonials>
        <Testimonials.Card
          avatar="/static/images/home-avatar-kevin.webp"
          name="Kevin Powell"
          title="Web Developer & YouTuber"
        >
          I highly recommend Frontend Mentor. Skip the search for project ideas
          and dive into ready-made challenges that help you level up as a
          developer.
        </Testimonials.Card>

        <Testimonials.Card
          avatar="/static/images/home-avatar-jessica.webp"
          name="Jessica Chan"
          title="Web Developer & YouTuber"
        >
          Frontend Mentor is a win-win. You can sharpen your skills building
          websites and add finished projects to your portfolio to help land a
          job!
        </Testimonials.Card>
      </Testimonials>
    </div>
  );
}
