import NextLink from 'next/link';

import { Card, Heading, Image } from '@frontendmentorio/design-system-v2';

export default function Featured() {
  return (
    <div className="px-5 py-9">
      <div className="container-xl flex flex-col gap-7 items-center">
        <Heading level="2">As featured on...</Heading>

        <div className="flex flex-col gap-5 items-center w-full tablet:flex-row tablet:items-stretch">
          <NextLink
            className="max-w-[24rem] w-full tablet:max-w-full"
            href="https://css-tricks.com/front-end-challenges/"
          >
            <Card className="flex h-[6.5rem] items-center justify-center p-5 tablet:h-full">
              <Image
                alt="CSS Tricks"
                height={36}
                src="/static/images/logo-css-tricks.svg"
                width={214}
              />
            </Card>
          </NextLink>

          <NextLink
            className="max-w-[24rem] w-full tablet:max-w-full"
            href="https://stackoverflow.blog/2021/04/30/podcast-334-a-curious-journey-from-personal-trainer-to-frontend-mentor/"
          >
            <Card className="flex h-[6.5rem] items-center justify-center p-5 tablet:h-full">
              <Image
                alt="Stack Overflow"
                height={40}
                src="/static/images/logo-stack-overflow.svg"
                width={200}
              />
            </Card>
          </NextLink>

          <NextLink
            className="max-w-[24rem] w-full tablet:max-w-full"
            href="https://www.producthunt.com/products/frontend-mentor"
          >
            <Card className="flex h-[6.5rem] items-center justify-center p-5 tablet:h-full">
              <Image
                alt="Product Hunt"
                height={50}
                src="/static/images/logo-product-hunt.svg"
                width={210}
              />
            </Card>
          </NextLink>
        </div>
      </div>
    </div>
  );
}
