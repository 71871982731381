import { useEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';
import { hasCookie } from 'cookies-next';
import { getRedirectPath } from 'lib/helpers';

import { login } from 'features/auth/slice';
import { fetchCount } from 'features/learners/slice';
import { useDispatch } from 'react-redux';

import {
  flash,
  LoadingScreen,
  useModal,
} from '@frontendmentorio/design-system-v2';

import Benefits from './Benefits';
import Community from './Community';
import CTA from './CTA';
import FAQs from './FAQs';
import Featured from './Featured';
import Hero from './Hero';
import Hiring from './Hiring';
import HowItWorks from './HowItWorks';
import Layout from 'features/shared/Layout';
import LoginFailureModal from './LoginFailureModal';
import Pro from './Pro';
import Testimonials from './Testimonials';

export default function Home({ query }) {
  const modal = useModal();

  const dispatch = useDispatch();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const getRedirectPathAfterAuth = (isNew) => {
    if (isNew) return '/home?intro=true';

    return getRedirectPath() || '/home';
  };

  const handleGithubAuth = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(login({ code: query.code })).unwrap();
      const { isNew } = response.meta;

      router.push(getRedirectPathAfterAuth(isNew));
    } catch (error) {
      setIsLoading(false);
      router.replace('/');

      if (error.code !== 422) return flash.error(error.message);

      setErrorMessage(error.message);
      modal.open();
    }
  };

  useEffect(() => {
    if (!query.code) return;
    if (!modal.open) return;

    handleGithubAuth();
  }, [modal.open]);

  return (
    <Layout
      description="Improve your front-end skills by building real projects. Solve real-world HTML, CSS and JavaScript challenges whilst working to professional designs."
      image="https://res.cloudinary.com/dz209s6jk/image/upload/v1585172856/Meta/meta-homepage.png"
      url="https://www.frontendmentor.io/"
    >
      {isLoading && <LoadingScreen />}

      <Hero />
      <Testimonials />
      <Featured />
      <Benefits />
      <HowItWorks />
      <Pro />
      <Community />
      <FAQs />
      <CTA />
      <Hiring />

      <LoginFailureModal message={errorMessage} modal={modal} />
    </Layout>
  );
}

Home.getInitialProps = async ({ query, store, ...ctx }) => {
  if (hasCookie('fem_token', ctx)) {
    if (ctx.res) {
      ctx.res.writeHead(307, {
        Location: `${process.env.LEARNING_PLATFORM_URL}/home`,
      });

      ctx.res.end();
    } else {
      Router.push('/home');
    }
  }

  await store.dispatch(fetchCount());

  return { query };
};
