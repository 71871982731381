import { Avatar } from '@frontendmentorio/design-system-v2';

export default function Card({ avatar, children, id, name, username }) {
  return (
    <li
      className="bg-neutralWhite flex flex-col gap-5 justify-between p-5 rounded-xl snap-start"
      id={id}
    >
      <span className="text-neutralDarkGrey">{children}</span>

      <div className="flex gap-3 items-center">
        <Avatar image={avatar} />

        <div className="flex flex-col gap-1 min-w-0">
          <span className="font-bold text-primaryBlue">{name}</span>

          <span className="overflow-hidden text-ellipsis text-neutralDarkGrey">
            @{username}
          </span>
        </div>
      </div>
    </li>
  );
}
