import { Modal } from '@frontendmentorio/design-system-v2';

const LoginFailureModal = ({ message, modal }) => {
  return (
    <Modal id="login-failure" modal={modal}>
      <Modal.Heading>We could not log you in</Modal.Heading>
      <p>
        An existing Frontend Mentor account already uses your GitHub username or
        primary email address.
      </p>
      <p>
        This usually occurs if you delete your old GitHub account and create a
        new one with the same details.
      </p>
      <div className="bg-neutralLightestGrey flex flex-col gap-5 items-center rounded-xl p-5">
        <p className="font-semibold">{message}</p>
      </div>
    </Modal>
  );
};

export default LoginFailureModal;
